export default [
  {
    path: '/login-next',
    name: 'auth-login-next',
    component: () => import('@/views/pages/authentication/LoginSaemless.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard-partner',
    component: () => import('@/views/Partner/dashboard/dashboard.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-lotto-type',
    name: 'manage-lotto-type',
    component: () => import('@/views/Partner/manage/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/Partner/manage/test.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bet-report',
    name: 'bet-report',
    component: () => import('@/views/Partner/lotto/bet-report.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bet-report-member',
    name: 'bet-report-member',
    component: () => import('@/views/Partner/lotto/bet-report-memberV2.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bet-report-memberOld',
    name: 'bet-report-memberOld',
    component: () => import('@/views/Partner/lotto/bet-report-memberOld.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-lotto-type-agent',
    name: 'manage-lotto-type-agent',
    component: () => import('@/views/Partner/manage/agent.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit',
    name: 'deposit',
    component: () => import('@/views/Partner/deposit/deposit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Withdraw',
    name: 'withdraw',
    component: () => import('@/views/Partner/withdraw/withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member',
    name: 'member',
    component: () => import('@/views/Partner/member/member.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/AddMember',
    name: 'addmember',
    component: () => import('@/views/Partner/member/add.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Withdraw/:id',
    name: 'Deposit-Withdraw',
    component: () => import('@/views/Partner/history/deposit_withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Lotto',
    name: 'lotto',
    component: () => import('@/views/Partner/lotto/lotto.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/LottoAgent',
    name: 'LottoAgent',
    component: () => import('@/views/Partner/lotto/lotto_agentgroup.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Group',
    name: 'group',
    component: () => import('@/views/Partner/lotto/group.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Type',
    name: 'type',
    component: () => import('@/views/Partner/lotto/type.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-time',
    name: 'manage-time',
    component: () => import('@/views/Partner/lotto/manage-time.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/Partner/lotto/pay.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/rate',
    name: 'rate',
    component: () => import('@/views/Partner/lotto/rate.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/set-bet',
    name: 'setbet',
    component: () => import('@/views/Partner/lotto/setbet.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/lotto-list',
    name: 'lotto-list',
    component: () => import('@/views/Partner/lotto/lotto-list.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/lotto-list-detail/:id',
    name: 'lotto-list-detail',
    component: () => import('@/views/Partner/lotto/lotto-list-detail.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-friend',
    name: 'invite-friend',
    component: () => import('@/views/Partner/lotto/ref.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/History-Play/:id',
    name: 'History-Play',
    component: () => import('@/views/Partner/history/play.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/win-loss',
    name: 'win-loss',
    component: () => import('@/views/Partner/history/winloss.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/waitting-result',
    name: 'waitting-result',
    component: () => import('@/views/Partner/history/waitting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  // assistant manager
  {
    path: '/assistant/create',
    name: 'assistant-create',
    component: () => import('@/views/Partner/assistant/create.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/assistant',
    name: 'assistant',
    component: () => import('@/views/Partner/assistant/list.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/assistant/permission',
    name: 'assistant-permission',
    component: () => import('@/views/Partner/assistant/permission.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/assistant/ban',
    name: 'assistant-ban',
    component: () => import('@/views/Partner/assistant/ban.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },

  // END assistant

  {
    path: '/win-loss-user',
    name: 'win-loss-user',
    component: () => import('@/views/Partner/history/winlossuser.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-all',
    name: 'sms-all',
    component: () => import('@/views/Partner/sms/smsAll.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-deposit',
    name: 'sms-deposit',
    component: () => import('@/views/Partner/sms/smsDeposit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-withdraw',
    name: 'sms-withdraw',
    component: () => import('@/views/Partner/sms/smsWithdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-otp',
    name: 'sms-otp',
    component: () => import('@/views/Partner/sms/otp.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/statement',
    name: 'statement',
    component: () => import('@/views/Partner/sms/statement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/statement-kbank',
    name: 'statement-kbank',
    component: () => import('@/views/Partner/statement/statement_Kbank.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/true-money',
    name: 'true-money',
    component: () => import('@/views/Partner/sms/trueMoney.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('@/views/Partner/admin/bank.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/Partner/admin/promotion.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('@/views/Partner/admin/channel.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/admin-management',
    name: 'admin-management',
    component: () => import('@/views/Partner/admin/admin.vue'),
    meta: {
      resource: 'AgMange',
      action: 'read',
    },
  },
  {
    path: '/admin-agent-management',
    name: 'admin-agent-management',
    component: () => import('@/views/Partner/admin/admin-agent.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/Partner/admin/setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/agent-list',
    name: 'agent-list',
    component: () => import('@/views/Partner/admin/agent-list.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/agent',
    name: 'agent',
    component: () => import('@/views/Partner/admin/agent.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-agent/:id',
    name: 'manage-agent',
    component: () => import('@/views/Partner/admin/agent-manage/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  // {
  //   path: '/manage-agent/:id',
  //   name: 'manage-agent',
  //   component: () => import('@/views/Partner/admin/manage-agent.vue'),
  //   meta: {
  //     resource: 'Admin',
  //     action: 'read',
  //   },
  // },
  {
    path: '/report-win-lose',
    name: 'report',
    component: () => import('@/views/Partner/admin/reports.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/scb-capcha/:ID',
    name: 'scb',
    component: () => import('@/views/Partner/scb.vue'),
    meta: {
      layout: 'full',
      // redirectIfLoggedIn: false,
      resource: 'Auth',
    },
  },
  // Member
  {
    path: '/buy-now/:LottoName/:LottoHead/:LottoSubHead/:CloseDate/:round_id',
    name: 'buy-now',
    component: () => import('@/views/Partner/buy/view.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/lotto-list-member',
    name: 'lotto-list-member',
    component: () => import('@/views/Partner/buy/lotto-list.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/lotto-list-memberV2',
    name: 'lotto-list-memberV2',
    component: () => import('@/views/Partner/buy/lotto-listV2.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/lotto-list-detail-member/:id',
    name: 'lotto-list-detail-member',
    component: () => import('@/views/Partner/buy/lotto-list-detail.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/lotto-report-member',
    name: 'lotto-report-member',
    component: () => import('@/views/Partner/buy/lotto-report.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/deposit-now',
    name: 'deposit-now',
    component: () => import('@/views/Partner/deposit/depoBank.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/buy-now-v1',
    name: 'buy-now-v1',
    component: () => import('@/views/Partner/buy-new/buy-huay.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/buy-huay',
    name: 'buy-huay',
    component: () => import('@/views/Partner/buy-new/buy-huay.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/round-huay',
    name: 'round-huay',
    component: () => import('@/views/Partner/buy-new/round.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/winlose-new',
    name: 'winlose-new',
    component: () => import('@/views/Partner/history/winlossuser-new.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/result-win',
    name: 'result-win',
    component: () => import('@/views/Partner/buy-new/result-win.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/result-win-detail/:id',
    name: 'result-win-detail',
    component: () => import('@/views/Partner/buy/result-win-detail.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },
  {
    path: '/Lotto-result',
    name: 'lotto-result',
    component: () => import('@/views/Partner/buy/lotto-result.vue'),
    meta: {
      resource: 'Member',
      action: 'read',
    },
  },

  // tanghuay online
  {
    path: '/tanghuay-home',
    name: 'tanghuay-home',
    component: () => import('@/views/Partner/tanghuay-online/home.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-deposit',
    name: 'tanghuay-deposit',
    component: () => import('@/views/Partner/tanghuay-online/deposit.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-withdraw',
    name: 'tanghuay-withdraw',
    component: () => import('@/views/Partner/tanghuay-online/withdraw.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/:LottoName/:LottoHead/:LottoSubHead/:CloseDate/:round_id',
    name: 'tanghuay-online',
    component: () => import('@/views/Partner/tanghuay-online/tanghuay.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-round',
    name: 'tanghuay-round',
    component: () => import('@/views/Partner/tanghuay-online/round.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/result',
    name: 'tanghuay-result',
    component: () => import('@/views/Partner/tanghuay-online/result.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/winlose',
    name: 'tanghuay-winlose',
    component: () => import('@/views/Partner/tanghuay-online/winlose.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/list',
    name: 'tanghuay-list',
    component: () => import('@/views/Partner/tanghuay-online/huay-list.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/list-detail/:id',
    name: 'tanghuay-list-detail',
    component: () => import('@/views/Partner/tanghuay-online/huay-list-detail.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  // Agent Saemless Manage
  {
    path: '/share',
    name: 'share',
    component: () => import('@/views/Partner/manage_saemless/share.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/share',
    name: 'share-type',
    component: () => import('@/views/Partner/manage_saemless/share-type.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/manage-bet-report-member',
    name: 'manage-bet-report-member',
    component: () => import('@/views/Partner/manage_saemless/bet-report-member.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-bet-report',
    name: 'manage-bet-report',
    component: () => import('@/views/Partner/manage_saemless/bet-report.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-waitting-result',
    name: 'manage-waitting-result',
    component: () => import('@/views/Partner/manage_saemless/waitting-result.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-lotto-list',
    name: 'manage-lotto-list',
    component: () => import('@/views/Partner/manage_saemless/lotto-list.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-set-bet',
    name: 'manage-set-bet',
    component: () => import('@/views/Partner/manage_saemless/setbet.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-win-loss',
    name: 'manage-win-loss',
    component: () => import('@/views/Partner/manage_saemless/winloss.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-win-loss-subagent',
    name: 'manage-win-loss-subagent',
    component: () => import('@/views/Partner/manage_saemless/winloss_subag.vue'),
    meta: {
      resource: 'SubAgent',
      action: 'read',
    },
  },
  {
    path: '/manage-win',
    name: 'manage-win',
    component: () => import('@/views/Partner/manage_saemless/win.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-lotto-status',
    name: 'manage-lotto-status',
    component: () => import('@/views/Partner/manage_saemless/lottostatus.vue'),
    meta: {
      resource: 'AgMange',
      action: 'read',
    },
  },
  {
    path: '/manage-rate',
    name: 'manage-rate',
    component: () => import('@/views/Partner/manage_saemless/rate.vue'),
    meta: {
      resource: 'AgMange',
      action: 'read',
    },
  },
  {
    path: '/manage-closebet',
    name: 'limit-closebet',
    component: () => import('@/views/Partner/manage_saemless/limitclosebet.vue'),
    meta: {
      resource: 'AgMange',
      action: 'read',
    },
  },
  // Master Saemless Manage
  {
    path: '/manage-master-rate',
    name: 'manage-master-rate',
    component: () => import('@/views/Partner/manage_master_saemless/rate.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/manage-master-round',
    name: 'manage-master-round',
    component: () => import('@/views/Partner/manage_master_saemless/round.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/manage-master-round-auto',
    name: 'manage-master-round-auto',
    component: () => import('@/views/Partner/manage_master_saemless/round-auto.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/announce',
    name: 'announce',
    component: () => import('@/views/Partner/manage_master_saemless/announce.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/lotto-status',
    name: 'lotto-status',
    component: () => import('@/views/Partner/manage_master_saemless/lottostatus.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/manage-master-result',
    name: 'manage-master-result',
    component: () => import('@/views/Partner/manage_master_saemless/result.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/manage-master-win-loss',
    name: 'manage-master-win-loss',
    component: () => import('@/views/Partner/manage_master_saemless/winloss.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/manage-descending',
    name: 'manage-descending',
    component: () => import('@/views/Partner/manage_master_saemless/descending.vue'),
    meta: {
      resource: 'AgMange',
      action: 'read',
    },
  },
  {
    path: '/manage-master-win-loss-detail/:id',
    name: 'manage-master-win-loss-detail',
    component: () => import('@/views/Partner/manage_master_saemless/winlossdetail.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/manage-admin-agent',
    name: 'manage-admin-agent',
    component: () => import('@/views/Partner/manage_master_saemless/admin-agent.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },

  // new route ban huay
  {
    path: '/add-member',
    name: 'add-member',
    component: () => import('@/views/Partner/home-manage/addMember.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/add-agent',
    name: 'add-agent',
    component: () => import('@/views/Partner/home-manage/addAgent.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/edit-agent/:id',
    name: 'edit-agent',
    component: () => import('@/views/Partner/home-manage/editAgent.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/member-list',
    name: 'member-list',
    component: () => import('@/views/Partner/home-manage/memberList.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/member/ban',
    name: 'member-ban',
    component: () => import('@/views/Partner/home-manage/memberBan.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/member/setting',
    name: 'member-setting',
    component: () => import('@/views/Partner/home-manage/memberSetting.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/member/history',
    name: 'history-list',
    component: () => import('@/views/Partner/home-manage/history.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
]
